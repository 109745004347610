.theme-brutalist {
  --font-global: "Poppins", sans-serif;
  --font-alt: "Epilogue", sans-serif;
  --container-width: 1300px;
  --section-padding-y: 160px;
  --color-dark-1: #111;
  --color-dark-2: #202020;
  --color-gray-1: #777;
  --gradient-primary-1: linear-gradient(
    90deg,
    var(--color-primary-4) 0%,
    var(--color-primary-3) 33%,
    var(--color-primary-2) 67%,
    var(--color-primary-1) 100%
  );
  --gradient-primary-1-a: linear-gradient(
    90deg,
    var(--color-primary-4) 25%,
    var(--color-primary-3) 40%,
    var(--color-primary-2) 60%,
    var(--color-primary-1) 75%
  );
  --gradient-primary-1-b: linear-gradient(
    45deg,
    var(--color-primary-4) 0%,
    var(--color-primary-3) 33%,
    var(--color-primary-2) 67%,
    var(--color-primary-1) 100%
  );
  --gradient-primary-1-c: linear-gradient(
    0deg,
    var(--color-primary-4) 0%,
    var(--color-primary-3) 33%,
    var(--color-primary-2) 67%,
    var(--color-primary-1) 100%
  );
  --gradient-gray-light-1: linear-gradient(0deg, #f5f5f5 0%, #fff 100%);
  --gradient-gray-light-2: linear-gradient(0deg, #fff 0%, #f5f5f5 100%);
  --gradient-dark-alpha-1: linear-gradient(
    90deg,
    var(--color-dark-1) 40%,
    transparent 87%
  );
  --gradient-dark-alpha-2: linear-gradient(
    90deg,
    transparent 13%,
    var(--color-dark-1) 60%
  );
  --gradient-primary-alpha-1: linear-gradient(
    90deg,
    var(--color-primary-1) 40%,
    transparent 87%
  );
  --gradient-primary-alpha-2: linear-gradient(
    90deg,
    transparent 13%,
    var(--color-primary-1) 60%
  );
  color: var(--color-dark-1);
  font-family: var(--font-global);
  font-size: 18px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.67;
}
.theme-brutalist
  .row:not(
    .g-0,
    .g-1,
    .g-2,
    .g-3,
    .g-4,
    .g-5,
    .gx-0,
    .gx-1,
    .gx-2,
    .gx-3,
    .gx-4,
    .gx-5,
    .gy-0,
    .gy-1,
    .gy-2,
    .gy-3,
    .gy-4,
    .gy-5
  ) {
  --bs-gutter-x: 24px;
}
/* .theme-brutalist h1,
.theme-brutalist h2,
.theme-brutalist h3,
.theme-brutalist h4,
.theme-brutalist h5,
.theme-brutalist h6,
.theme-brutalist .h1,
.theme-brutalist .h2,
.theme-brutalist .h3,
.theme-brutalist .h4,
.theme-brutalist .h5,
.theme-brutalist .h6 {
  font-weight: 600;
} */
.theme-brutalist .inner-nav ul {
  font-size: 15px;
  line-height: 1.6;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.08em;
}
.theme-brutalist .form input[type="text"],
.theme-brutalist .form input[type="email"],
.theme-brutalist .form input[type="number"],
.theme-brutalist .form input[type="url"],
.theme-brutalist .form input[type="search"],
.theme-brutalist .form input[type="tel"],
.theme-brutalist .form input[type="password"],
.theme-brutalist .form input[type="date"],
.theme-brutalist .form input[type="color"],
.theme-brutalist .form select {
  padding-bottom: 1px;
}
.theme-brutalist .form input[type="text"]::placeholder,
.theme-brutalist .form input[type="email"]::placeholder,
.theme-brutalist .form input[type="number"]::placeholder,
.theme-brutalist .form input[type="url"]::placeholder,
.theme-brutalist .form input[type="search"]::placeholder,
.theme-brutalist .form input[type="tel"]::placeholder,
.theme-brutalist .form input[type="password"]::placeholder,
.theme-brutalist .form input[type="date"]::placeholder,
.theme-brutalist .form input[type="color"]::placeholder,
.theme-brutalist .form select::placeholder {
  color: #999;
}
.theme-brutalist .form label {
  margin-bottom: 14px;
  font-family: var(--font-alt);
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}
.theme-brutalist .form .form-group {
  margin-bottom: 25px;
}
.theme-brutalist .form-tip,
.theme-brutalist .form-tip a {
  font-size: 13px;
}
.theme-brutalist .light-content .form input.newsletter-field {
  border-color: rgba(255, 255, 255, 0.35);
}
.theme-brutalist .light-content .form input.newsletter-field:hover {
  border-color: rgba(255, 255, 255, 0.5);
}
.theme-brutalist .light-content .form input.newsletter-field:focus {
  border-color: rgba(255, 255, 255, 0.75);
}
.theme-brutalist .btn-mod,
.theme-brutalist a.btn-mod {
  font-family: var(--font-alt);
  font-weight: 400;
}
.theme-brutalist .btn-mod.btn-small {
  padding: 6px 14px 4px;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.08em;
}
.theme-brutalist .btn-mod.btn-medium {
  padding: 8px 16px 6px;
  font-size: 15px;
  font-weight: 400;
  vertical-align: middle;
  letter-spacing: 0.08em;
}
.theme-brutalist .btn-mod.btn-large {
  padding: 15px 32px 13px 32px;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.08em;
}
.theme-brutalist .btn-mod.btn-border,
.theme-brutalist .btn-mod.btn-border-w {
  border-width: 1px;
}
.theme-brutalist .section-title-large {
  line-height: 1.2;
  letter-spacing: -0.025em;
}
.theme-brutalist .section-title-descr {
  font-size: 15px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
}
.theme-brutalist .light-content .section-title-descr {
  color: #fff;
}
.theme-brutalist .link-strong {
  font-family: var(--font-alt);
  font-size: 15px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.025em;
}
.theme-brutalist .link-strong svg {
  position: relative;
  top: -0.1em;
  max-width: unset;
  max-height: unset;
  margin-left: 0.25em;
}
.theme-brutalist .post-prev-1-title {
  font-weight: 500;
}
.theme-brutalist .widget-title {
  font-weight: 600;
}
.theme-brutalist .blog-item-data {
  font-size: 16px;
}
.theme-brutalist .blog-item-body {
  font-size: 19px;
  line-height: 1.65;
  letter-spacing: -0.0125em;
}
.theme-brutalist .pagination a,
.theme-brutalist .pagination span {
  font-weight: 400;
}
.theme-brutalist .fw-social-inline .link-strong {
  letter-spacing: 0.075em;
}
.theme-brutalist .footer-1 {
  font-family: var(--font-alt);
  text-transform: uppercase;
  letter-spacing: 0.075em;
}
.theme-brutalist .features-2-item:before {
  border: 1px solid var(--color-dark-1);
  background: none;
  opacity: 0.15;
  transition: opacity 0.27s var(--ease-default);
  z-index: -1;
}
.theme-brutalist .features-2-item:hover:before {
  opacity: 1;
}
.theme-brutalist .features-2-label {
  font-weight: 500;
  color: var(--color-dark-1);
  background: none;
  -webkit-background-clip: unset;
  -webkit-text-fill-color: unset;
}
.theme-brutalist .features-2-icon:before {
  background: var(--color-gray-light-1);
  opacity: 1;
  z-index: -1;
}
.theme-brutalist .features-2-icon svg {
  fill: var(--color-dark-1);
}
.theme-brutalist .light-content .features-2-item:before {
  background: var(--color-dark-mode-gray-2);
  border: 1px solid var(--color-dark-mode-gray-2);
  transition: border-color 0.27s var(--ease-default);
  opacity: 1;
  z-index: -1;
}
.theme-brutalist .light-content .features-2-item:hover:before {
  background: var(--color-dark-mode-gray-2);
  opacity: 1;
}
.theme-brutalist .light-content .features-2-label {
  color: #fff;
  background: none;
  -webkit-background-clip: unset;
  -webkit-text-fill-color: unset;
}
.theme-brutalist .light-content .features-2-icon {
  color: #fff;
}
.theme-brutalist .light-content .features-2-icon svg {
  fill: #fff;
}
.theme-brutalist .light-content .features-2-icon:before {
  background: var(--color-dark-mode-gray-2);
  opacity: 1;
  z-index: -1;
}
.theme-brutalist .light-content .features-2-descr {
  color: var(--color-dark-mode-gray-1);
}
.theme-brutalist .bg-border-gradient:before {
  background: none;
  border: 1px solid var(--color-dark-1);
  opacity: 0.15;
}
.theme-brutalist .light-content .bg-border-gradient:before {
  background: none;
  border: 1px solid #fff;
  opacity: 0.25;
}
.theme-brutalist .bg-gradient-dark-alpha-1:before {
  opacity: 0.9;
}
.theme-brutalist .bg-gradient-dark-alpha-2:before {
  opacity: 0.9;
}
.theme-brutalist .row.gx-huge {
  --bs-gutter-x: 170px;
}
.theme-brutalist .features-1-title {
  font-weight: 600;
  letter-spacing: -0.03em;
}
.theme-brutalist .work-navigation a,
.theme-brutalist .work-navigation a:visited {
  color: var(--color-dark-1);
}
@media only screen and (min-width: 1441px) {
  .theme-brutalist {
    --container-width: 1360px;
  }
}
@media only screen and (min-width: 1681px) {
  .theme-brutalist {
    --container-width: 1500px;
  }
}
@media only screen and (max-width: 1366px) {
  .theme-brutalist {
    --section-padding-y: 140px;
  }
  .theme-brutalist .row.gx-huge {
    --bs-gutter-x: calc(-0.802rem + 13.38vw);
  }
}
